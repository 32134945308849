<template>
    <AppLayout>
        <v-row justify="center">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div class="pa-5 text-center" v-if="loading">
                    <p class="text-h5 font-weight-light">Loading...</p>
                </div>
                <div class="pa-5 text-center" v-if="errorUnknownInteraction">
                    <p class="text-h5 font-weight-light red--text">We cannot process this request.</p>
                </div>
            </v-col>
        </v-row>
    </AppLayout>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import AppLayout from '@/components/AppLayout.vue';
import loginshield from '@/client';

export default {
    components: {
        AppLayout,
    },
    data() {
        return {
            interactionId: null,
            isViewReady: false,
            loading: true,
            errorUnknownInteraction: false,
        };
    },
    methods: {
        async init() {
            console.log('interaction.vue: init; isAuthenticated: %o', this.isAuthenticated);

            if (this.$route.query.token) {
                try {
                    this.$store.commit('loading', { useInteractionToken: true });
                    const tokenResponse = await loginshield.interaction.resumeWithToken(this.$route.query.token);
                    if (tokenResponse.interactionId) {
                        this.interactionId = tokenResponse.interactionId;
                        // rewrite the location so a reload here (or a back button from next view)
                        // will have the interaction id in the URL so we don't try to use the token again
                        this.$router.replace({ path: '/interaction', query: { i: this.interactionId } });
                    } else {
                        this.errorUnknownInteraction = true;
                    }
                } catch (err) {
                    console.error('failed to resume interaction with token', err);
                    this.errorUnknownInteraction = true;
                } finally {
                    this.$store.commit('loading', { useInteractionToken: false });
                }
            } else if (this.$route.query.i) {
                this.interactionId = this.$route.query.i;
            } else {
                this.errorUnknownInteraction = true;
            }

            if (this.interactionId) {
                this.fetchInteractionInfo();
            }
        },
        linkAccountUser(/* { type, next, state } */) {
            this.$router.push({ path: '/link-account-user', query: { i: this.interactionId } });
        },
        linkRealmUser(/* { type, next, state } */) {
            this.$router.push({ path: '/register', query: { i: this.interactionId } });
        },
        async login({ type, next, state }) {
            if (this.isAuthenticated) {
                // authenticated (got here after login) then redirect to wherever we need to go
                if (typeof state === 'object') {
                    console.log('interaction.vue: login: state: %o', state);
                    if (next) {
                        console.log('interaction.vue: next interaction: %s', next);
                        this.interactionId = next;
                        await this.fetchInteractionInfo({ type, state }); // start over with next interaction id, and pass in the current interaction for reference
                        return;
                    }
                    if (state.forward) {
                        // TODO: is this correct?  if the forward url is to our own site, we should be using $router.push ...
                        console.log('interaction.vue: redirecting to: %s', state.forward);
                        window.location = state.forward;
                        return;
                    }
                    // if there's no other business, redirect to the dashboard
                    this.$router.push({ path: '/dashboard' });
                }
            } else {
                // not yet authenticated so redirect to login
                this.$router.push({ path: '/login', query: { i: this.interactionId } });
            }
        },
        async verifyEmail({ type, next, nextRoute, state }) {
            const { isVerified, email } = state;
            console.log('interaction.vue: verifyEmail: email: %s', email);
            console.log('interaction.vue: verifyEmail: isVerified: %o', isVerified);
            if (isVerified) {
                if (nextRoute && nextRoute.startsWith('/')) {
                    this.$router.push(nextRoute);
                    return;
                }
                if (next) {
                    this.interactionId = next;
                    await this.fetchInteractionInfo({ type, state }); // start over with next interaction id, and pass in the current interaction for reference
                }
            }
        },
        async view({ /* type, next, */ state }) {
            const { path /* , query, hash */ } = state;
            console.log('interaction.vue: view: path: %s', path);
            /* if (path && query) {
                console.log('interaction.vue: view: redirecting to path: %s with query: %s', path, query);
                this.$router.push(`${path}?${query}`);
            } else
            */
            if (path && path.startsWith('/')) {
                console.log('interaction.vue: view: redirecting to path: %s', path);
                this.$router.push(path);
            } else {
                console.error('interaction.vue: invalid view location; path is required');
            }
        },
        async registerUserQuick(/* { type, next, state } */) {
            console.log('interaction.vue: webauthz');
            this.$router.push({ path: '/register/quick', query: { i: this.interactionId } });
        },
        async webauthz({ /* type, next, */ state }) {
            console.log('interaction.vue: webauthz');
            const { status } = state;
            if (status === 'start') {
                this.$router.push({ path: '/webauthz/start', query: { i: this.interactionId } });
            } else {
                // NOTE: for status 'grant' and 'deny' we also redirect to prompt, and that's where we inform the user the access was already granted or denied
                this.$router.push({ path: '/webauthz/prompt', query: { i: this.interactionId } });
            }
        },
        async fetchInteractionInfo(prevInteraction = {}) {
            const interactionInfo = await this.$store.dispatch('loadInteraction', this.interactionId); // { type, next, nextRoute, state }
            if (!interactionInfo) {
                console.log('interaction.vue: interaction not found');
                this.errorUnknownInteraction = true;
                return;
            }
            const { type } = interactionInfo;
            console.log(`interaction.vue: fetchInteractionInfo: interaction info: ${type}`);
            switch (type) {
            case 'link_account_user':
                this.linkAccountUser(interactionInfo, prevInteraction);
                break;
            case 'link_realm_user':
                this.linkRealmUser(interactionInfo, prevInteraction);
                break;
            case 'login':
                this.login(interactionInfo, prevInteraction);
                break;
            case 'verify_email':
                this.verifyEmail(interactionInfo, prevInteraction);
                break;
            case 'login_realm_user':
                // should never happen because safety notice url for login realm user is at enterprise website
                console.error('interaction.vue: unexpected login_realm_user interaction');
                this.errorUnknownInteraction = true;
                break;
            case 'view':
                this.view(interactionInfo, prevInteraction);
                break;
            case 'register_user_quick':
                // used to register a new enterprise user/admin without a login (will happen later) to start a free trial
                this.registerUserQuick(interactionInfo, prevInteraction);
                break;
            case 'webauthz':
                this.webauthz(interactionInfo, prevInteraction);
                break;
            default:
                console.error('interaction.vue: unknown interaction type: %s', type);
                this.errorUnknownInteraction = true;
                break;
            }
        },
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    watch: {
        isReady(value, oldValue) {
            console.log('interaction.vue: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
    },
    mounted() {
        console.log('interaction.vue: mounted');
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
